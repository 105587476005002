import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Content from '../components/Content';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ContactPage(props) {
  const [state, handleSubmit] = useForm('mvodzkey');
  if (state.succeeded) {
    return <p>Thankyou</p>;
  }
  return (
    <div>
      <Jumbotron className='bg-transparent jumbotron-fluid p-0'>
        <Container>
          <Row className='justify-content-center py-5'>
            <Col md={8} sm={12}>
              <h1 className='display-1 font-weight-bolder'>Lets Talk</h1>
              <h3 className='lead font-weight-light'>
                Send me a message, I'd love to hear from you!
              </h3>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Content>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label htmlFor='full-name'>Full Name</Form.Label>
            <Form.Control id='full-name' name='name' type='text' />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor='email'>Email</Form.Label>
            <Form.Control id='email' name='email' type='email' />
          </Form.Group>

          <ValidationError prefix='Email' field='email' errors={state.errors} />

          <Form.Group>
            <Form.Label htmlFor='message'>Message</Form.Label>
            <Form.Control id='message' name='message' as='textarea' rows='3' />
          </Form.Group>

          <ValidationError
            prefix='Message'
            field='message'
            errors={state.errors}
          />

          <Button className='d-inline-block' variant='primary' type='submit'>
            Send
          </Button>
        </Form>
      </Content>
    </div>
  );
}

function Formspree() {
  return <ContactPage />;
}
export default Formspree;
